import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import './all.sass';
import useSiteMetadata from './SiteMetadata';
import { withPrefix, StaticQuery } from 'gatsby';

const TemplateWrapper = ({ data, children }) => {
  const { title, description } = useSiteMetadata();
  const navItems = data.allMarkdownRemark.edges[0].node.frontmatter.navItems;

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
      </Helmet>
      <Navbar navigations={navItems} />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

// Query for Header Navigation Items
const layoutQuery = graphql`
  query NavQuery {
    allMarkdownRemark(
      filter: { frontmatter: { settingKey: { eq: "setting-navigation" } } }
    ) {
      edges {
        node {
          frontmatter {
            navItems {
              title
              navlink
              children {
                title
                navlink
              }
            }
          }
        }
      }
    }
  }
`;

// Make the query and pass results to the component.
export default (props) => (
  <StaticQuery
    query={layoutQuery}
    render={(data) => (
      <TemplateWrapper data={data} children={props.children}></TemplateWrapper>
    )}
  ></StaticQuery>
);
